.header {
 background-color: #ffffff;
 color: white;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 padding: 10px 0;
}

.logo {
 position: absolute;
 top: -5px;
 left: 10px;
}

.about {
 position: absolute;
 top: 10px;
 right: 10px;
}

.container {
 position: relative;
}
